import React, { Fragment, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import { withSnackbar } from '../../../hoc/withSnackbar';
import {
  AlignItemInCenter,
  FilterHeading,
  MediumHeading,
  FilterContainer
} from '../../Dashboard/styles';
import STFilters from '../../../common/STFilters';
import { CURRENCY_SYMBOL } from '../../../../constants';
import {
  getApiResponseObject,
  getAuthToken,
  showApiErrorToast,
  successStatusCodes
} from '../../../../utils';
import { API_BASE_URL } from '../../../../config/env';
import { toaster } from '../../../atoms/toaster';
import PaginationControls from '../../../atoms/paginationControls';
import SearchBar from '../../../atoms/searchBar';
import {
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ProductContainer } from '../../../organisms/Catalog/styles';
import Icons from '../../../atoms/icons';
import { isEmpty } from 'lodash';
import noBannerImage from '../../../../assets/images/no-preview-available.png';
import shoptypeThemes from '../../../../theme';
import AutoCompleteCustomChips from '../../../common/AutoCompleteCustomChips';
import { PanelContent } from '../../AwakeMoney/Dashboard/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import FilterIcon from '../../../../assets/icons/filter.svg';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: 90,
    maxHeight: 110,
    margin: '0px 8px 8px 0px',
    display: 'flex',
    backgroundColor: '#F5F5F5',
    border: 'none',
    boxShadow: 'none'
  },
  w100: {
    width: '100%',
    paddingTop: '9px',
    height: '850px',
    overflow: 'auto',
    overflowX: 'hidden'
  },
  addButton: {
    position: 'absolute',
    right: '-2px',
    top: '-23px',
    color: 'green'
  },
  media: {
    height: 60,
    minWidth: 80,
    backgroundSize: 'contain',
    marginTop: '15px',
    marginLeft: '5px'
  },
  pos: {
    marginBottom: 12
  },
  secondButton: {
    textTransform: 'none',
    marginTop: 8
  },
  cardContent: {
    'padding-bottom': 0,
    width: 'inherit'
  },
  cardTypography: {
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    width: '75%'
  },
  descTypography: {
    fontSize: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    width: '75%'
  },
  paddingContainer: {
    padding: '20px'
  },
  halfBlock: {
    width: '50%',
    display: 'table-cell'
  },
  productSearch: {
    width: '100%',
    backgroundColor: '#F5F5F5',
    border: 'none',
    marginTop: '15px',
    marginBottom: '10px'
  },
  pRel: {
    position: 'relative',
    boxSizing: 'border-box',
    width: 'inherit'
  },
  tabs: {
    backgroundColor: shoptypeThemes.default.WILD_SAND,
    position: 'sticky',
    top: -24,
    zIndex: 999,
    boxShadow: '0 3px 2px -2px rgba(0,0,0,.1)',
    marginTop: 20
  },
  tab: {
    color: shoptypeThemes.default.MINE_SHAFT,
    fontSize: shoptypeThemes.default.LARGE,
    textTransform: 'none'
  },
  selectedTab: {
    backgroundColor: shoptypeThemes.default.RONCHI,
    height: '3px'
  },
  flexContainer: {
    justifyContent: 'space-around'
  },
  filterIcon: {
    cursor: 'pointer',
    width: '30px',
    height: '23px',
    marginTop: '4px'
  },
  cancelButton: {
    position: 'absolute',
    right: '4px',
    top: '-3px',
    color: 'red'
  },
  filterBtn: {
    textTransform: 'none',
    color: shoptypeThemes.default.INVOLVED
  }
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
      style={{ height: '100%' }}
    >
      {value === index && (
        <PanelContent style={{ padding: 0 }}>{children}</PanelContent>
      )}
    </div>
  );
}
function CollectionFiltersPanel(props) {
  const classes = useStyles();
  const [vendorFilters, updatevendorFilters] = useState([]);
  const [fetchParams, setFetchParams] = useState({
    count: 10, // set this for pagesize
    offset: 0,
    text: '',
    vendorId: '',
    tags: ''
  });
  const [vendorProducts, setVendorProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [totalResultsCount, setTotalResultsCount] = useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [tagsTotalCount, setTagsTotalCount] = useState(0);

  const [preSelectedTags, setPreSelectedTags] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [fetchParamsTags, setFetchParamsTags] = useState({
    count: 10, // set this for pagesize
    offset: 0,
    text: ''
  });
  useEffect(() => {
    fetchLinkedVendors();
  }, []);

  useEffect(() => {
    if (props.isDynamicCollection) {
      let param = {
        count: 10,
        offset: 0,
        text: ''
      };
      if (isEmpty(props.selectedCollectionForUpdate?.product_query)) {
        setPreSelectedTags([]);
      }

      setFetchParamsTags(param);
    } else {
      setFetchParamsTags({
        count: 20,
        offset: 0,
        text: ''
      });
    }
  }, [props.isDynamicCollection]);

  useEffect(() => {
    setTotalResultsCount(0);
    setSelectedProducts([]);
    setVendorProducts([]);
    if (props.payload.type === 'self') {
      setShowFilter(false);
      setSelectedTabIndex(0);
    }
    if (!isEmpty(props.payload)) {
      setFetchParams({
        count: 10,
        offset: 0,
        text: '',
        vendorId: '',
        tags: ''
      });
      setFetchParamsTags({
        count: 10,
        offset: 0,
        text: ''
      });
    }
  }, [props.payload.type]);

  useEffect(() => {
    if (!isEmpty(props.selectedCollectionForUpdate?.product_query)) {
      setPreSelectedTags(
        props.selectedCollectionForUpdate?.product_query?.tags
      );
      props.updateSelectedTags(
        props.selectedCollectionForUpdate?.product_query?.tags
      );
    }
    fetchVendorProducts();
  }, [props.selectedCollectionForUpdate]);

  useEffect(() => {
    fetchTags();
  }, [preSelectedTags]);
  useEffect(() => {
    let { selectedCollectionForUpdate } = props;
    if (!isEmpty(selectedCollectionForUpdate)) {
      if (isProduct()) {
        setSelectedProducts(selectedCollectionForUpdate?.product_details || []);
      } else {
        setSelectedProducts(selectedCollectionForUpdate?.collections || []);
      }
    }
  }, [props.payload]);

  const handleTabChange = (_, newSelectedTabIndex) => {
    updateFetchParams(prevState => {
      return {
        ...prevState,
        count: 10,
        offset: 0,
        text: ''
      };
    });
    setSelectedTabIndex(newSelectedTabIndex);
  };

  const fetchVendorProducts = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    let reqUrl = '';
    if (isProduct()) {
      reqUrl = `${API_BASE_URL}/query-products`;
    } else {
      reqUrl = `${API_BASE_URL}/networks/collections?status=published`;
    }
    if (props.payload.type) {
      const response = await getApiResponseObject(reqUrl, headers, fetchParams);
      if (successStatusCodes.includes(response.status)) {
        let resData = isProduct()
          ? response.data.product || []
          : response.data.collections || [];
        if (!isEmpty(props.selectedCollectionForUpdate)) {
          let productIds = isProduct()
            ? props.selectedCollectionForUpdate?.product_details?.map(
              ele => ele.id
            )
            : props.selectedCollectionForUpdate?.collections?.map(
              ele => ele.id
            );
          let filteredProducts = Object.assign([], resData);
          filteredProducts = filteredProducts.filter(
            ele => !productIds?.includes(ele.id)
          );
          if (selectedProducts?.length > 0) {
            let selectedProductIds = selectedProducts.map(ele => ele.id);
            let finalProductList = Object.assign([], filteredProducts);
            finalProductList = finalProductList.filter(
              ele => !selectedProductIds?.includes(ele.id)
            );

            setVendorProducts(finalProductList);
          } else {
            setVendorProducts(filteredProducts);
          }
        } else {
          if (selectedProducts?.length > 0) {
            let productIds = selectedProducts.map(ele => ele.id);
            let filteredProducts = Object.assign([], resData);
            filteredProducts = filteredProducts.filter(
              ele => !productIds?.includes(ele.id)
            );

            setVendorProducts(filteredProducts);
          } else {
            setVendorProducts(resData);
          }
        }

        setTotalResultsCount(response.data.count);
      } else {
        showApiErrorToast(response.data);
        setTotalResultsCount(0);
        setVendorProducts([]);
      }
    }
  };
  useEffect(() => {
    fetchVendorProducts();
  }, [fetchParams]);

  useEffect(() => {
    fetchTags();
  }, [fetchParamsTags]);

  useEffect(() => {
    if (!isEmpty(props.deletedProduct)) {
      setVendorProducts(prevState => [...prevState, props.deletedProduct]);
      const filterIndex = selectedProducts.indexOf(props.deletedProduct);
      const newFilterProducts = [...selectedProducts];
      newFilterProducts.splice(filterIndex, 1);
      setSelectedProducts(newFilterProducts);
    }
  }, [props.deletedProduct]);

  useEffect(() => {
    if (!isEmpty(props.deletedTag)) {
      const filterIndex = preSelectedTags.indexOf(props.deletedTag);
      const selectedTags = [...preSelectedTags];
      selectedTags.splice(filterIndex, 1);
      setTagsOptions(prevState => [...prevState, props.deletedTag]);
      updateTags(selectedTags);
    }
  }, [props.deletedTag]);

  useEffect(() => {
    props.updateSelectedProducts(selectedProducts);
  }, [selectedProducts]);

  useEffect(() => {
    if (props.isDynamicCollection) {
      props.updateSelectedTags(preSelectedTags);
    }
  }, [preSelectedTags]);

  const updateFetchParams = data => {
    setTotalResultsCount(0);
    setFetchParams(data);
  };

  const updateTagsFetchParams = data => {
    setTagsTotalCount(0);
    setFetchParamsTags(data);
  };

  const clearFilters = () => {
    setClearFilter(true);
  };

  const updateTags = data => {
    setPreSelectedTags(data);
    let selectedTags = data.length > 0 ? data.join(',') : '';
    props.updateSelectedTags(selectedTags.split(','));
    if (!props.isDynamicCollection) {
      updateFetchParams(prevState => {
        return {
          ...prevState,
          tags: selectedTags,
          offset: 0
        };
      });
    }
  };
  const changeTagOptionBasedOnValue = data => {
    setFetchParamsTags({
      count: 10,
      offset: 0,
      text: data
    });
  };

  const fetchLinkedVendors = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const response = await getApiResponseObject(
      `${API_BASE_URL}/networks/manage-vendors`,
      headers
    );
    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    } else {
      const vendorListDataRawFormat = response.data.vendors;
      if (!Array.isArray(vendorListDataRawFormat)) {
        return toaster('Unable to fetch vendors');
      } else {
        const vendorsList = vendorListDataRawFormat.map(vendor => {
          return {
            id: vendor.vendor.id,
            name: vendor.vendor.name,
            value: vendor.vendor.id
          };
        });
        updatevendorFilters(vendorsList);
      }
    }
  };

  const showProductsContainer = () => {
    return (
      !props.isDynamicCollection ||
      (props.isDynamicCollection && selectedTabIndex === 0)
    );
  };

  const fetchTags = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const response = await getApiResponseObject(
      `${API_BASE_URL}/tags`,
      headers,
      fetchParamsTags
    );
    if (successStatusCodes.includes(response.status)) {
      let finalTags = response.data.tags.map(ele => ele.key);
      finalTags = finalTags.filter(ele => !preSelectedTags?.includes(ele));
      setTagsOptions(finalTags);
      setTagsTotalCount(response.data.count);
    }
  };
  const onAdd = product => {
    const filterIndex = vendorProducts.indexOf(product);
    const newFilterProducts = [...vendorProducts];
    newFilterProducts.splice(filterIndex, 1);
    setVendorProducts(newFilterProducts);
    setSelectedProducts(prevState => [...prevState, product]);
  };

  const onAddTags = tag => {
    const filterIndex = tagsOptions.indexOf(tag);
    const newFilterTags = [...tagsOptions];
    newFilterTags.splice(filterIndex, 1);
    setTagsOptions(newFilterTags);
    setPreSelectedTags(prevState => [...prevState, tag]);
  };

  const updateVendorOffset = fetchParam => {
    if (
      fetchParams.offset < totalResultsCount - 1 &&
      vendorProducts?.length + selectedProducts?.length < totalResultsCount
    ) {
      updateFetchParams(fetchParam);
    }
  };

  const updateTagsOffset = fetchParam => {
    updateTagsFetchParams(fetchParam);
  };

  const isProduct = () => {
    return props.payload.type === 'product';
  };

  const getEmptyStateMessage = () => {
    return isProduct() ? 'product' : 'collection';
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
    setClearFilter(false);
  };
  return (
    <>
      <FilterHeading>
        Select your {isProduct() ? 'products' : 'collections'} here
        {isProduct() && (
          <img
            src={FilterIcon}
            onClick={toggleFilter}
            className={classes.filterIcon}
          />
        )}
      </FilterHeading>
      {showFilter && (
        <FilterContainer>
          <IconButton
            aria-label="add"
            className={classes.cancelButton}
            onClick={() => toggleFilter()}
          >
            <CancelIcon />
          </IconButton>
          <STFilters
            fetchParams={fetchParams}
            filterList={vendorFilters}
            isMulti={true}
            filterName={'Vendors'}
            updateFetchParams={updateFetchParams}
            height={180}
            clearFilter={clearFilter}
          />
          <Button
            className={classes.filterBtn}
            color="primary"
            onClick={clearFilters}
          >
            Clear Filters
          </Button>
        </FilterContainer>
      )}
      <Tabs
        className={classes.tabs}
        value={selectedTabIndex}
        TabIndicatorProps={{ className: classes.selectedTab }}
        onChange={handleTabChange}
        classes={{ flexContainer: isProduct() ? classes.flexContainer : null }}
      >
        <Tab
          className={classes.tab}
          label={isProduct() ? 'Search by products' : 'Search by collections'}
        />
        {isProduct() && <Tab className={classes.tab} label="Search by tags" />}
      </Tabs>

      <TabPanel value={selectedTabIndex} index={0}>
        <SearchBar
          placeholder={isProduct() ? 'Product Search' : 'Collection Search'}
          fetchParams={fetchParams}
          updateFetchParams={updateFetchParams}
          className={classes.productSearch}
        />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        {props.isDynamicCollection ? (
          <SearchBar
            placeholder={'Tags Search'}
            fetchParams={fetchParamsTags}
            updateFetchParams={updateTagsFetchParams}
            className={classes.productSearch}
          />
        ) : (
          <AutoCompleteCustomChips
            options={tagsOptions}
            handleChange={updateTags}
            label="Tags Search"
            className={classes.productSearch}
            changeOptionBaseOnValue={changeTagOptionBasedOnValue}
            selectedTags={preSelectedTags}
          />
        )}
      </TabPanel>

      {showProductsContainer() ? (
        <>
          {(fetchParams.text !== '' ||
            fetchParams.vendorId !== '' ||
            fetchParams.tags !== '') && (
              <>
                <MediumHeading>{totalResultsCount} results found</MediumHeading>
              </>
            )}
          <ProductContainer>
            <div className={classes.w100}>
              {!isEmpty(vendorProducts) ? (
                vendorProducts.map((product, index) => {
                  if (product.id !== props.collectionID) {
                    return (
                      <div key={product.id} className={classes.pRel}>
                        <IconButton
                          aria-label="add"
                          className={classes.addButton}
                          onClick={() => onAdd(product)}
                        >
                          <AddCircleIcon />
                        </IconButton>

                        <Card className={classes.root} key={index}>
                          <img
                            className={classes.media}
                            src={
                              isProduct()
                                ? product?.primaryImageSrc?.imageSrc ||
                                noBannerImage
                                : product.preview_image_src || noBannerImage
                            }
                          />
                          <CardContent className={classes.cardContent}>
                            <Typography
                              className={classes.cardTypography}
                              gutterBottom
                              variant="h6"
                              component="h6"
                            >
                              {isProduct() &&
                                product.variants &&
                                product.variants.length > 0 && (
                                  <Fragment>
                                    Starting {CURRENCY_SYMBOL[product.currency]}{' '}
                                    {product.variants[0].discountedPrice} |
                                  </Fragment>
                                )}
                              {isProduct() ? product.title : product.name}
                            </Typography>

                            <Typography
                              className={classes.descTypography}
                              variant="body1"
                              color="textSecondary"
                              component="p"
                            >
                              {product.description || '-'}
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                    );
                  }
                })
              ) : (
                <AlignItemInCenter>
                  No {getEmptyStateMessage()} found
                </AlignItemInCenter>
              )}
            </div>
          </ProductContainer>
          <PaginationControls
            fetchParams={fetchParams}
            totalItemCount={totalResultsCount}
            updateFetchParams={updateVendorOffset}
          />
        </>
      ) : (
        <>
          <ProductContainer>
            <div className={classes.w100}>
              {!isEmpty(tagsOptions) ? (
                tagsOptions.map((tag, index) => {
                  return (
                    <div key={index} className={classes.pRel}>
                      <IconButton
                        aria-label="add"
                        className={classes.addButton}
                        onClick={() => onAddTags(tag)}
                      >
                        <AddCircleIcon />
                      </IconButton>

                      <Card className={classes.root} key={index}>
                        <CardContent className={classes.cardContent}>
                          <Typography
                            className={classes.cardTypography}
                            gutterBottom
                            variant="h6"
                            component="h6"
                          >
                            {tag}
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })
              ) : (
                <AlignItemInCenter>No Tags found</AlignItemInCenter>
              )}
            </div>
          </ProductContainer>
          <PaginationControls
            fetchParams={fetchParamsTags}
            totalItemCount={tagsTotalCount}
            updateFetchParams={updateTagsOffset}
          />
        </>
      )}
    </>
  );
}

export default withSnackbar(CollectionFiltersPanel);
